<template>
    <div>
        <v-card class="rounded-lg" elevation="4">
            <v-tabs fixed-tabs background-color="#327d3c" dark>
                <v-tabs-slider color="#ceded0"></v-tabs-slider>
                <v-tab>ACOMPANHAMENTO DE EXAMES DIÁRIOS</v-tab>

                <v-tab-item>
                    <v-card-text>
                        <v-container>
                            <p style="font-weight:bold"> Paciente Doador: {{ this.donor_name }}</p>
                        </v-container>

                        <v-form @submit.prevent="saveExamsForm" ref="form" :disabled="disbaleForm">
                            <v-container>
                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="HB"
                                        outlined
                                        v-model="examsForm.hb" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Glicemia"
                                        outlined
                                        v-model="examsForm.glucose" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Diurese 24H"
                                        outlined
                                        v-model="examsForm.diuresis" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="HT"
                                        outlined
                                        v-model="examsForm.ht" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="TP"
                                        outlined
                                        v-model="examsForm.tp" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Hemoderivados"
                                        outlined
                                        v-model="examsForm.blood_products" 
                                        dense></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Leuco"
                                        outlined
                                        v-model="examsForm.silly" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="RNI"
                                        outlined
                                        v-model="examsForm.rni" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Drogas Vasoativas"
                                        outlined
                                        v-model="examsForm.vasoative_drugs" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Bastões"
                                        outlined
                                        v-model="examsForm.canes" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Amilase"
                                        outlined
                                        v-model="examsForm.amylase" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Antibióticos"
                                        outlined
                                        v-model="examsForm.antibiotics" 
                                        dense></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Segmentados"
                                        outlined
                                        v-model="examsForm.segmented" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="TGO"
                                        outlined
                                        v-model="examsForm.tgo" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="PA"
                                        outlined
                                        v-model="examsForm.pa" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="PLTS"
                                        outlined
                                        v-model="examsForm.plts" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="TGP"
                                        outlined
                                        v-model="examsForm.tgp" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="PAM"
                                        outlined
                                        v-model="examsForm.pam" 
                                        dense></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Uréia"
                                        outlined
                                        v-model="examsForm.urea" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="FOSF.ALC"
                                        outlined
                                        v-model="examsForm.fosf" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="FC"
                                        outlined
                                        v-model="examsForm.fc" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="CREAT"
                                        outlined
                                        v-model="examsForm.creat" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Gamagt"
                                        outlined
                                        v-model="examsForm.gamagt" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="T(ºC)"
                                        outlined
                                        v-model="examsForm.t_graus" 
                                        dense></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Na"
                                        outlined
                                        v-model="examsForm.na" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="BIL.TOTAL"
                                        outlined
                                        v-model="examsForm.bil_total" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="BIL.DIRETA"
                                        outlined
                                        v-model="examsForm.bil_direta" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="SAT.O²"
                                        outlined
                                        v-model="examsForm.sat" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="K"
                                        outlined
                                        v-model="examsForm.k" 
                                        dense></v-text-field>
                                    </v-col>
                                    <!-- <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="PCR"
                                        outlined
                                        v-model="examsForm.pcr" 
                                        dense></v-text-field> solicitaram a remoção desse campo (05/02/2024)
                                    </v-col> -->
                                </v-row> 

                               <p class="space-personalized"></p>
                                <p style="font-weight:bold"> Controle das Gasometrias para Doação de Pulmão:</p>
                               <br>

                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="FIO²"
                                        outlined
                                        v-model="examsForm.fio" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="pH"
                                        outlined
                                        v-model="examsForm.ph" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="PO²"
                                        outlined
                                        v-model="examsForm.po" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="PCO²"
                                        outlined
                                        v-model="examsForm.pco" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-text-field 
                                        color="#256936" 
                                        label="SAT.O²"
                                        outlined
                                        v-model="examsForm.sato2_pulmao" 
                                        dense></v-text-field>
                                    </v-col>
                                </v-row>

                                <!-- Início da Tabela -->
                                <v-data-table
                                    :headers="headers"
                                    :items="entrace_exams"
                                    :footer-props="{'items-per-page-text': 'Doador por Página',}"
                                    :loading="loading"
                                    v-if="enableTable == true">
                            
                                    <template v-slot:[`item.actions`]="{ item }" :resource="item">
                                    <v-tooltip bottom color="#3fa118">
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-icon 
                                            size="22"
                                            class="icon"
                                            color="#3fa118" 
                                            align-item="center"
                                            v-on="on"
                                            v-bind="attrs"
                                            @click="openView(item)">mdi-eye 
                                        </v-icon>
                                        </template>
                                        <span style="color:white">Visualizar Exame</span>
                                    </v-tooltip>
                                    <v-tooltip right color="#2ea8ff">
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-icon 
                                            size="22"
                                            class="icon"
                                            color="#2ea8ff" 
                                            align-item="center"
                                            v-on="on"
                                            v-bind="attrs"
                                            @click="openViewEdit(item)">mdi-pencil 
                                        </v-icon>
                                        </template>
                                        <span style="color:white">Editar Exame</span>
                                    </v-tooltip>
                                    </template>
                                </v-data-table>

                                <v-dialog persistent width="500px" v-model="confirmDialog">
                                    <v-card class="deathDialog">
                                        <v-card-title class="text-h5">
                                            <v-icon dark class="donorIcon">mdi-checkbox-marked-circle</v-icon>
                                            Exame Registrado!!
                                        </v-card-title>
                                        <h4>Lembre-se que este acompanhamento deverá ser feito diariamente.</h4>
                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="#72ed82"
                                                variant="text"
                                                value="Sim"
                                                @click="enableDialog()"
                                            >
                                                ok
                                            </v-btn>
                                            </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-dialog persistent width="1024" v-model="viewDialog">
                                    <v-card class="deathDialog">
                                        <v-card-title class="text-h5">
                                            <v-icon dark class="donorIcon">mdi-eye</v-icon>
                                                Visualizar:
                                        </v-card-title>
                                            <DialogComp />
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="#72ed82"
                                                    variant="text"
                                                    value="Sim"
                                                    @click="enable()"
                                                >
                                                    ok
                                                </v-btn>
                                            </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <!-- <div class="space-personalized"></div> --> 
                                <br>
                                <div class="buttons">
                                    <v-row>
                                        <v-btn type="submit" class="rounded-lg" elevation="0" color="#327d3c" v-if="enableButton == true">
                                            <span class="textBtn">Salvar</span>
                                        </v-btn>
                                        <v-btn class="rounded-lg" elevation="0" color="#327d3c" v-if="enableButtonEdit == true" @click="edit(item)">
                                            <span class="textBtn">Atualizar</span>
                                        </v-btn>
                                        <p class="space-personalized"></p>
                                        <v-btn class="rounded-lg" v-if="disableButton == false" elevation="0" color="#32a641" @click="changeTable()">
                                            <span class="textBtn">Visualizar Exames Anteriores</span>
                                        </v-btn>
                                        <p class="space-personalized"></p>
                                        <v-btn class="rounded-lg" elevation="0" color="grey"  @click="$router.push('/doador')">
                                            <span class="textBtn">Sair</span>
                                        </v-btn>
                                    </v-row>
                                </div>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-tab-item>
            </v-tabs>
        </v-card>

        <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Fechar
          </v-btn>
        </template>
    </v-snackbar>

    </div>
</template>
  
<script>
import axios from "@/axios"
export default {
    data() {
        return {
            headers: [
                {
                    text: "Data de Entrada de Exame",
                    value: "created_at",
                    align: "center",
                },
                {
                    text: "Responsável pelo cadastro",
                    value: "users.name",
                    align: "center",
                },
                {
                    text: "Ações",
                    value: "actions",
                    align: "center",
                }
            ],
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            donor_id: "",
            itemID: "",
            loading: true,
            options: {},
            donor_name: "",
            entrace_exams: [],
            message: "",
            snackbar: false,
            messageError: "",
            enableButton: true,
            enableButtonEdit: false,
            enableTable: false,
            disbaleForm: false,
            disableButton: false,
            confirmDialog: "",
            viewDialog: "",
            examsForm: {
                donor_id: "",
                user_id: ""
            },
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },
    created() {
        
    },
    watch: {
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },

    methods: {

        indoDataTable() {
            this.loading = true;
                const donor = this.$route.query.item;
                    this.donor_name = donor.name;
                        this.donor_id = donor.id;
                            axios.get(`/entrace_exams/${this.donor_id}`).then((response) => {
                                this.loading = false;
                                this.entrace_exams = response.data;
                            });
        },

        ative() {
            this.confirmDialog = true;
        },
        enableDialog() {
            this.confirmDialog = false;
        },

        enable(){
            this.viewDialog = false;
        },

        changeTable() {
            this.enableTable = true;
            this.disableButton = true;
            this.indoDataTable();
        },

        saveExamsForm() {
            this.examsForm.donor_id = this.donor_id;
            this.examsForm.user_id = this.$store.state.authUser.id;
            const exams = this.examsForm;
            axios
                .post(`/entrace_exams`, exams)
                .then(() => {
                this.confirmDialog = true;
                this.examsForm = "";
                this.enableButton = false;
                this.dialog = false;
                this.indoDataTable();
            })
                .catch((err) => {
                err;
                var error = "";
                Object.values(this.$store.state.errorMessage).map((value) => {
                    error = value;
                });
                this.displayError.message = error.toString();
                this.displayError.display = true;
                setTimeout(() => {
                    this.displayError.display = false;
                }, 3000);
            });
        },

        openViewEdit(item) {
            this.examsForm = item
            this.itemID = item.id
            this.disbaleForm = false
            this.enableButton = false
            this.enableButtonEdit = true
        },

        openView(item){
            this.examsForm = item
            this.disbaleForm = true
            this.enableButton = false
        },

        edit(){
            const exame = this.examsForm
            axios
            .put(`/entrace_exams/${this.itemID}`, exame)
            .then((response) => {
                this.message = response.data.message;
                this.snackbar = true;
            })
            .catch((err) => {
                err;
                var error = "";
                Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
                });

                this.displayError.message = error.toString();
                this.displayError.display = true;
                setTimeout(() => {
                this.displayError.display = false;
                }, 3000);
            });
        }
    },
    mounted() {
        this.indoDataTable();
    },
};
</script>
  
<style scoped>
.deathDialog{
    background-color: #fafffc;
}
.deathDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.text-h5{
    background-color: #41a84f;
    color: white;
}
.donorIcon{
    padding:  8px 8px 8px 0;
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p{
    font-size: 16px;
    /* font-weight: bold; */
}
.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #0ca673,
      #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #0cb087,
      #66f9b9);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.textBtn{
  color:#ffffff;
}
.banner>h3 {
  color: #ffffff;
  padding: 18px 0 18px 0;
}
.modalIcon{
  padding: 12px;
}

 .cardFooter {
    padding: 0 !important;
}
</style>
  